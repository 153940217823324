body {
  /* font-family: "Roboto", "Arial", "Helvetica", sans-serif; */
  font-family: "Fabrikat", sans-serif;
  background-color: #1f1f1f; /* Dark background */
  color: #e0e0e0; /* Light gray text */
}
@font-face {
  font-family: "Fabrikat"; /* The name you will use in your CSS */
  src: url("/src/font/fabrikat-kompakt-font-family-1729202473-0/HvDTrial_FabrikatKompakt-Light-BF64d599868b650.otf")
      format("otf"),
    url("/src/font/fabrikat-kompakt-font-family-1729202473-0/HvDTrial_FabrikatKompakt-Black-BF64d5998612cf0.otf")
      format("otf"),
    url("/src/font/fabrikat-kompakt-font-family-1729202473-0/HvDTrial_FabrikatKompakt-Medium-BF64d599863ad82.otf")
      format("otf");
  font-weight: normal; /* You can set to bold if your font has a specific weight */
  font-style: normal; /* Change if you have italic, etc. */
}

h1,
h2 {
  font-weight: 700; /* Bold font for headings */
}

p,
span {
  font-weight: 400; /* Normal font weight for smaller text */
  font-size: 14px; /* Base font size */
}

.important-text {
  color: #ffffff; /* White color for important text */
}

.red {
  color: #ed4b62; /* Red color for negative metrics */
}

.green {
  color: hsl(158.97deg 38.65% 50.78%); /* Green color for positive metrics */
}
.numbers {
  font-family: "Fabrikat", sans-serif;
  font-weight: 400; /* Normal font weight */
}
.dotted {
  border-bottom: 1px dashed #999;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
}

.offset {
  width: 40px;
  height: 20px;
}
.right-icon {
  font-size: 23px !important;
  margin-left: auto;
  padding: 0px 32px;
}
.logo {
  display: flex;
  width: 28px;
  height: 28px;
  font-family: monospace;
  font-size: 22px;
  border-radius: 4px;
  font-weight: 400;
  margin: 0px 8px 0px 8px;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* font-weight: 400; */
}
.logo-long {
  background-color: #51b290;
}

.logo-short {
  background-color: #ed4b62;
}

.spacer {
  height: 42px;
}

button {
  font-family: "Fabrikat", sans-serif;
  font-weight: 500;
  font-size: 17px;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.card-header {
  display: flex;
  justify-content: space-between; /* Ensures even spacing between elements */
  align-items: center;
  height: 60px;
  margin: 0px 5px;
}

.logo img {
  width: 40px;
}

.card-title {
  display: flex;
  flex-direction: row;
  text-align: center;
  flex-grow: 1; /* Ensures card-title takes available space */
}
span.gmt {
  font-size: 28px;
  font-weight: 450;
}
span.gmt.gray {
  color: hsl(222.5deg 12.9% 36.47%);
}

.gmt-badge {
  background-color: #29313d;
  border-radius: 3px;
  font-family: "Fabrikat", sans-serif;
  font-size: 15px;
  padding: 2px 12px;
  margin: 8px 2px;
  color: #cbd0d8;
}

.card-body {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  /* width: 570px;  */
  height: 245px; /* Fixed height for the card body */
}

.col {
  width: 45%; /* Set fixed width for columns */
}

.bold {
  font-weight: bold;
  font-size: 26px;
}
.card-footer {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.action-btn {
  height: 50px;
  width: 182px;
  font-size: 20px;
  font-weight: 500;
  background-color: #353e4b;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}

.label {
  color: hsl(220deg 8.18% 56.86%);
  white-space: nowrap;
  font-weight: 400;
  word-spacing: 3px;
  margin-block: 7px;
}

.form-container {
  width: 34%;
  box-shadow: 1px 5px 50px #8e8e9fcf;
  background-color: #fffafb;
  padding: 2% 2%;
  margin: 1% auto;
  border-radius: 8px;
  color: #000000;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 9px 2%;
  background-color: #e1e1e4;
  border: none;
  border-radius: 4px;
  color: #000000;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #00c58e;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.card-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.gmt-card {
  background-color: #1c2431;
  font-size: 19px;
  padding: 12px 0px;
  color: white;
  width: 600px;
  height: 400px;
}

/* Media queries for responsiveness */
@media (max-width: 666px) {
  .form-container {
    width: 90%;
    padding: 10px 10px;
  }

  .card-container {
    justify-content: flex-start;
    padding: 0 10px;
  }

  .gmt-card {
    width: 600px !important;
    height: 400px !important;
    flex-shrink: 0 !important; /* Prevent card from shrinking */
  }
}
